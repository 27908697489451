<template>
	<div class="page">
		<div class="login-box">
			<div class="sys-info">
				<!-- 				<div class="logo">
					<img src="../../assets/bright-logo.png" alt="logo">
				</div> -->
				<h1>智慧养老后台管理系统</h1>
			</div>
			<div class="form">
				<el-form :model="form" ref="form" :rules="rules" label-width="label-width" size="normal" @submit.native.prevent>
					<el-form-item prop="username">
						<el-input v-model="form.username" placeholder="请输入账号">
							<i slot="prefix" class="el-input__icon el-icon-user"></i>
						</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input type="password" v-model="form.password" placeholder="请输入密码">
							<i slot="prefix" class="el-input__icon el-icon-lock"></i>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" native-type="submit" style="width: 100%;" @click="onSubmit">登录
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					username: '',
					password: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			onSubmit() {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.$util.post('login', this.form).then(res => {
							sessionStorage.setItem('adminer', res.data.name)
							sessionStorage.setItem('token', res.data.api_token)
							sessionStorage.setItem('userHead', res.data.photo)
							sessionStorage.setItem('roleList', JSON.stringify(res.data.roles))
							// 当前登录用户的 id
							sessionStorage.setItem('systemUserId', res.data.id)

							if (res.data.staff && res.data.staff.sex) {
								sessionStorage.setItem('sex', res.data.staff.sex)
							} else {
								sessionStorage.setItem('sex', 0)
							}
							// 如果登录时query.redirect为真，说明是token过期后重定向到登录页的
							if (this.$route.query.redirect) {
								this.$router.push(this.$route.query.redirect)
							} else {
								this.$router.push('/')
							}
						})
					} else {
						return alert('账号密码错误')
					}
				})
			},
			toRegister() {
				this.$router.push('/register')
			},
			toForget() {
				this.$router.push('/forget')
			}
		}
	}
</script>

<style scoped>
	.page {
		position: relative;
		height: 100vh;
		background: url('../../assets/bg.jpg');
		background-size: 100vw 100vh;
	}

	.bg {
		height: 100%;
	}

	.bg .el-image {
		width: 100%;
		height: 100%;
	}

	.login-box {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 400px;
		transform: translate(-50%, -50%);
		background-color: #fff;
		border-radius: 10px;
		box-shadow: rgba(255, 255, 255, .4) 0 0 20px;
		z-index: 99;
		padding: 30px;
		box-sizing: border-box;
	}

	.logo {
		width: 150px;
		margin-bottom: 20px;
	}

	h1 {
		font-size: 18px;
		margin: 0;
	}

	h1~p {
		font-size: 14px;
		color: #999;
		margin: 0;
	}

	.sys-info {
		margin-bottom: 22px;
	}

	.other {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		color: #409EFF;
	}
</style>